<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="showHelpDialog = true" color="primary">
                            <v-icon>help</v-icon>
                            {{ $t('generic.lang_nav_help') }}
                        </v-btn>
                    </v-card-actions>

                    <v-row>
                        <v-col cols="12">
                            <v-img
                                height="70"
                                src="@/assets/images/hs3_large.jpeg"
                                contain
                                style="margin-top: 30px; margin-bottom: 30px;"
                            ></v-img>
                        </v-col>
                    </v-row>

                    <v-toolbar dark flat>
                        <v-toolbar-title>HS3 Komfort</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="getSettings" :loading="loading" :disabled="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text v-if="loading && receiptsLastScan === null" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-card-text>

                    <v-card-text v-else>
                        <p>
                            <b>{{ $t('settings.lang_lastSynchronizationOfInvoices') }}</b>
                            <span v-if="receiptsLastScan.timestamp > 0">
                                <br>{{ receiptsLastScan.date }}
                            </span>
                            <v-chip v-else style="margin-left: 20px;" small label color="primary">{{ $t('generic.lang_none') }}</v-chip>
                        </p>

                        <p>
                            <b>{{ $t('generic.lang_totalGuests') }}:</b>
                            <br>{{ stats.totalGuests }}
                        </p>

                        <p>
                            <b>{{ $t('generic.lang_occupancyTotal') }}:</b>
                            <br>{{ stats.totalOccupations }}
                        </p>
                    </v-card-text>

                    <!-- ARTIKEL STAMM -->
                    <v-divider></v-divider>

                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('settings.lang_articleMaster') }}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <p><b>{{ $t('settings.lang_initializeItem') }}:</b></p>

                        <div v-if="!syncSucess">
                            <v-alert text color="primary">
                                {{ $t('settings.lang_hereYouCanTransferYourCompleteArticleMasterToHS3') }}
                            </v-alert>

                            <v-btn color="success" :loading="loading2" :disabled="loading2" @click="syncWares">
                               {{ $t('settings.lang_transferItem') }}
                            </v-btn>
                        </div>

                        <v-alert v-else dark color="success" v-html="$t('settings.lang_theArticleMasterWasSuccessfullyTransferredToHS3')"></v-alert>
                    </v-card-text>

                    <!-- Settings -->
                    <v-divider></v-divider>

                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('settings.lang_settings') }}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <p><b>{{ $t('settings.lang_shouldAllSalesBeBookedInHS3') }}</b></p>

                        <v-checkbox :value="1" :disabled="loading3" v-model="parseAllReceipts"
                                    :label="$t('settings.lang_yesPostAllSalesInHS3')"></v-checkbox>

                        <v-btn color="success" @click="saveSettings" :loading="loading3" :disabled="loading3">
                            {{ $t('generic.lang_save') }}
                        </v-btn>
                    </v-card-text>

                    <!-- LOG FILE -->
                    <v-divider></v-divider>

                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('settings.lang_logFile') }}</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-btn color="primary" @click="showLogDialog = true">{{ $t('settings.lang_getLogFile') }}</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Error Dialog -->
        <v-dialog :value="syncWareErrorMessage.length > 0" persistent width="400">
            <v-card>
                <v-card-title>{{ $t('generic.lang_error') }}</v-card-title>
                <v-card-text class="text-center">{{ $t('settings.lang_articleMasterCouldNotBeTransferred') }}
                    <br><br><b>Fehler:</b><br>{{ syncWareErrorMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="syncWareErrorMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <HS3SettingsHelpDialog :show-dialog="showHelpDialog" @closeDialog="showHelpDialog = false"></HS3SettingsHelpDialog>
        <HS3SettingsLogDialog :show-dialog="showLogDialog" @closeDialog="showLogDialog = false"></HS3SettingsLogDialog>
    </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import HS3SettingsLogDialog from "@/components/settings/moduleSettings/HS3SettingsLogDialog";
import HS3SettingsHelpDialog from "@/components/settings/moduleSettings/HS3SettingsHelpDialog";

export default {
    name: "HS3Settings",

    components: {
        HS3SettingsHelpDialog,
        HS3SettingsLogDialog
    },

    data() {
        return {
            loading: false,
            loading2: false,
            loading3: false,
            syncWareErrorMessage: "",
            syncSucess: false,
            receiptsLastScan: null,
            stats: null,
            showLogDialog: false,
            parseAllReceipts: 0,
            showHelpDialog: false
        }
    },

    mounted() {
        this.getSettings();
    },

    methods: {
        getSettings() {
            this.loading = true;

            this.axios.post(ENDPOINTS.HS3.SETTINGS.GET).then((res) => {
                if (res.data.success) {
                    this.receiptsLastScan = res.data.receiptsLastScan;
                    this.stats = res.data.stats;
                    this.parseAllReceipts = res.data.settings.parseAllReceipts;
                }

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        syncWares() {
            this.loading2 = true;

            this.axios.post(ENDPOINTS.HS3.SYNCWARES).then((res) => {
                if (!res.data.success) {
                    this.syncWareErrorMessage = res.data.msg;
                } else {
                    this.syncSucess = true;
                }

                this.loading2 = false;
            }).catch((err) => {
                this.syncWareErrorMessage = err.message;

                this.loading = false;
            })
        },
        saveSettings() {
            this.loading3 = true;

            this.axios.post(ENDPOINTS.HS3.SETTINGS.UPDATE, {
                parseAllReceipts: this.parseAllReceipts
            }).then(() => {
                this.loading3 = false;
            }).catch(() => {
                this.loading3 = false;
            })
        }
    }
}
</script>