<template>
    <v-dialog v-model="showDialog" scrollable persistent width="800">
        <v-card>
            <v-card-title>{{ $t('generic.lang_nav_help') }}</v-card-title>
            <v-card-subtitle>HS3 Komfort</v-card-subtitle>

            <v-toolbar dark flat>
                <v-toolbar-title>{{ $t('generic.lang_installation') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="margin-top: 20px; font-size: 16px; max-height: 600px;">
                <p>
                    <span v-html="$t('settings.lang_downloadHS3HotelSoftware')"></span>
                    <br><a style="margin-left: 20px;" href="http://2020.hs3.de/" target="_blank">{{ $t('settings.lang_downloadHS32020') }}</a>
                </p>

                <p>
                    <span v-html="$t('settings.lang_downloadTheInterfaceSoftwareHS3Cash')"></span>
                    <br><a style="margin-left: 20px;" href="http://hs3cash.hs3.de/" target="_blank">{{ $t('settings.lang_downloadHS3Cash') }}</a>
                </p>

                <p v-html="$t('settings.lang_settingUpTheHS3CashInterface')"></p>
                <v-img src="@/assets/images/hs3cash_setup.png" contain height="500"></v-img>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="closeDialog">{{ $t('generic.lang_close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "HS3SettingsHelpDialog",

    props: {
        showDialog: Boolean
    },

    methods: {
        closeDialog() {
            this.$emit("closeDialog");
        }
    }
}
</script>