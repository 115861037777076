<template>
    <v-dialog v-model="showDialog" scrollable width="700" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t("settings.lang_interfaceLog") }}</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn :disabled="loading" :loading="loading" icon @click="getLogs">
                    <v-icon>refresh</v-icon>
                </v-btn>
                <v-btn icon @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- Loading Spinner -->
            <v-card-text v-if="loading" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-text>

            <v-card-text v-else style="max-height: 600px;">
                <v-alert v-if="log.length === 0" text class="text-center" color="primary">{{ $t("generic.lang_noEntriesAvailable") }}</v-alert>

                <p v-for="(logLine, index) in log" :key="index" v-html="logLine"></p>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="error" @click="closeDialog">{{$t("generic.lang_cancel")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.keep-spaces {
    white-space: pre;
    word-spacing: 10px;
}
</style>

<script>
import {ENDPOINTS} from "@/config";

export default {
    name: "HS3SettingsLogDialog",

    props: {
        showDialog: Boolean
    },

    data() {
        return {
            loading: false,
            log: []
        }
    },

    watch: {
        showDialog(val) {
            if (!val) {
                this.log = [];
            } else {
                this.getLogs();
            }
        }
    },

    methods: {
        getLogs() {
            this.loading = true;

            this.axios.post(ENDPOINTS.HS3.LOG).then((res) => {
                if (res.data.success) {
                    /*
                    let logContent = [];
                    logContent = atob(res.data.log);
                    logContent = logContent.split("\n");

                    this.log = logContent;
                     */

                    this.log = res.data.log;
                }

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        closeDialog() {
            this.$emit("closeDialog");
        }
    }
}
</script>