<template>
    <div>
        <page-title heading="HS3" subheading="HS3" :icon=icon></page-title>
        <div class="app-main__inner">
            <HS3Settings></HS3Settings>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import HS3Settings from "@/components/settings/moduleSettings/HS3Settings";

    export default {
        components: {
            HS3Settings,
            PageTitle
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>